.desktop-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50px; /* Adjust based on your design */
    background-color: rgb(255, 165, 0); /* Same as AppBar */
    z-index: 1; /* Ensure it's above other content */
  }
  
  .desktop-menu a {
    padding: 10px;
    color: white; /* Change to your desired text color */
  }
  
  .desktop-menu a:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Optional hover effect */
  }
  /* App.css */
.about-us {
  background-color: #f9f9f9; /* Light background for the section */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Limit width for better readability */
  margin: auto; /* Center the box */
}

h4 {
  color: #4a90e2; /* A vibrant color for headings */
}

h5 {
  color: #e94e77; /* A different color for subheadings */
  margin-top: 20px; /* Spacing before the subheading */
}

p {
  color: #333; /* Dark text for paragraphs */
  line-height: 1.6; /* Improved line spacing */
  margin-bottom: 15px; /* Space between paragraphs */
}

img {
  border: 2px solid #4a90e2; /* Border around images */
  border-radius: 8px; /* Rounded corners */
}

.button {
  background-color: #e94e77; /* Button color */
  color: white; /* Text color for button */
  padding: 10px 15px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

.button:hover {
  background-color: #d43d68; /* Darker shade on hover */
}
button {
  min-width: 48px; /* Larger button for better touch accuracy */
  z-index: 10;
}
@media (max-width: 600px) {
  .MuiTableCell-root {
    padding: 8px; /* Adjust padding for mobile */
  }
  table {
    width: 100%; /* Make the table responsive */
    display: block;
    overflow-x: auto;
  }
}
