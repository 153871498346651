/* UserList.css */

/* Container for the user list */
.user-list-container {
    padding: 16px;
    background-color: #f9f9f9;
}

/* Filters container */
.filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.filters input,
.filters select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Grid for user cards */
.user-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

/* Card for each user */
.user-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* User details inside each card */
.user-details h3 {
    font-size: 1.2em;
    margin: 0 0 10px;
}

.user-details p {
    margin: 5px 0;
    font-size: 0.9em;
}

/* Button styles */
.user-details button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 0.9em;
}

.user-details button:hover {
    background-color: #0056b3;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .user-list-container {
        padding: 8px;
    }

    .filters {
        flex-direction: column;
    }

    .user-card {
        width: 100%;
        max-width: none;
    }
}

@media (max-width: 480px) {
    .filters input,
    .filters select {
        padding: 8px;
    }

    .user-details h3 {
        font-size: 1.1em;
    }

    .user-details p {
        font-size: 0.8em;
    }

    .user-details button {
        padding: 8px 12px;
        font-size: 0.8em;
    }
}
