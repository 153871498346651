/* Main container styling */
.people-container {
  padding: 20px;
}

/* People list container */
.people-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Individual person item */
.person-item {
  flex: 1 1 calc(33.333% - 20px); /* Three items per row on larger screens */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .person-item {
    flex: 1 1 100%; /* One item per row on mobile */
  }
}

/* Card styling for each person */
.person-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Trophy icon */
.trophy-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.person-image {
  width: 100%;
  height: 300px; /* Fixed height for uniform image sizes */
  object-fit: cover;
  border-radius: 8px;
}

.person-info {
  margin-top: 10px;
}

/* Countdown timer */
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 10px;
  border: 2px solid #4CAF50;
  border-radius: 10px;
  background-color: #f0f8ff;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.countdown-container h2 {
  font-size: 1.5rem;
  color: #2e8b57;
  margin-bottom: 10px;
}

.countdown-timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d9534f;
  padding: 10px 20px;
  border: 2px solid #ff6347;
  border-radius: 5px;
  background-color: #fff;
}

@media (max-width: 600px) {
  .countdown-container {
    margin: 10px;
    padding: 8px;
  }

  .countdown-container h2 {
    font-size: 1.2rem;
  }

  .countdown-timer {
    font-size: 1rem;
    padding: 8px 15px;
  }
}

/* Zoomed image modal */
.zoomed-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.zoomed-image {
  max-width: 90%;
  max-height: 80%;
  border-radius: 8px;
}
