.admin-page {
    padding: 20px;
  }
  
  .controls {
    margin-bottom: 20px;
  }
  
  .participants-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .participant-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    width: calc(33.333% - 20px); /* Adjust based on screen size */
    box-sizing: border-box;
  }
  
  .participant-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer; /* Indicate that the image is clickable */
  }
  
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
  }
  
  .zoomed-image {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .participant-card {
      width: calc(50% - 20px); /* Adjust for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .participant-card {
      width: 100%; /* Full width for mobile screens */
    }
  }
  