.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: white;
    padding: 20px;
    overflow: hidden;
}

.modal-image-wrapper {
    max-height: 80vh; /* Adjust height as needed */
    overflow: auto; /* Enable scrolling */
}

.modal-image {
    width: 100%;
    height: auto;
    display: block;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
