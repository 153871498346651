.container {
    margin: 20px;
}

.title {
    font-family: 'Arial', sans-serif; /* Change this to your preferred font */
    font-size: 2.5rem; /* Adjust the size as needed */
    color: #333; /* Title color */
    margin-bottom: 20px; /* Space below title */
}

.description {
    font-family: 'Georgia', serif; /* Change this to your preferred font */
    font-size: 1.2rem; /* Adjust the size as needed */
    color: #555; /* Description color */
    margin-bottom: 30px; /* Space below description */
    line-height: 1.5; /* Line height for readability */
}
  
.winnerCard {
    position: relative;
  }
  
  .trophyOverlay {
    position: absolute;
    top: 10px;  /* Adjust to move the trophy down */
    right: 10px; /* Adjust to move the trophy left */
    width: 50px; /* Adjust the size of the trophy */
    z-index: 10; /* Ensures the trophy image is above the winner's image */
  }
  
  .winnerImage {
    width: 100%; /* Make sure the winner image fits the card */
    height: auto;
  }
  