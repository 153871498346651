/* General Table Styles */
.table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left; /* Align text to the left */
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .table th {
    background-color: #f4f4f4;
    white-space: nowrap;
  }
  
  /* Responsive Table */
  @media (max-width: 767px) {
    .table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .table thead {
      display: none; /* Hide headers on mobile */
    }
  
    .table tr {
      display: block;
      margin-bottom: 10px;
      border: 1px solid #ddd;
    }
  
    .table td {
      display: block;
      text-align: left; /* Align text to the left */
      position: relative;
      padding: 10px;
      margin-bottom: 10px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd;
      overflow: hidden;
    }
  
    .table td::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      padding: 0 10px;
      text-align: left; /* Align label to the left */
      background-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      visibility: hidden; /* Hide label on mobile */
    }
  
    .table td span.value {
      display: block;
      background-color: #ffff00; /* Highlight background color */
      padding: 2px 5px;
      border-radius: 4px;
      text-align: left; /* Ensure text alignment is left */
    }
  
    .table td span.label {
      display: none; /* Hide label on mobile */
    }
  }
  