/* Basic Reset and Mobile-friendly Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .report-page {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .date-filters {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduced gap between elements */
  }
  
  .date-filters label {
    display: flex;
    flex-direction: column;
    gap: 3px; /* Reduced gap between label text and input */
    font-size: 0.8rem; /* Reduced font size */
  }
  
  .date-filters input[type="date"] {
    width: 200px; /* Fixed width */
    padding: 8px; /* Reduced padding */
    font-size: 0.8rem; /* Reduced font size */
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .date-filters button {
    width: 200px; /* Fixed width */
    padding: 8px; /* Reduced padding */
    font-size: 0.8rem; /* Reduced font size */
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .date-filters button:hover {
    background-color: #0056b3;
  }
  
  .report-data {
    margin-top: 20px;
  }
  
  h2 {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #333;
  }
  
  h3 {
    font-size: 1rem;
    margin: 10px 0;
    color: #555;
  }
  
  .amount-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .amount-table th,
  .amount-table td {
    padding: 8px; /* Reduced padding */
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .amount-table th {
    background-color: #f8f9fa;
    font-size: 0.9rem;
  }
  
  .amount-table td {
    font-size: 0.9rem;
  }
  
  .amount-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Responsive Design for Small Screens */
  @media (max-width: 600px) {
    .date-filters {
      flex-direction: column;
      gap: 8px; /* Reduced gap between elements */
    }
  
    .date-filters input[type="date"],
    .date-filters button {
      width: 100%;
    }
  
    .report-data h2,
    .report-data h3 {
      font-size: 1rem;
    }
  
    .amount-table th,
    .amount-table td {
      font-size: 0.8rem;
      padding: 6px; /* Reduced padding */
    }
  
    .amount-table {
      font-size: 0.8rem;
    }
  }
  