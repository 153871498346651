.mobile-app-section {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-app-section h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .mobile-app-section p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .mobile-app-section a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .mobile-app-section a:hover {
    background-color: #0056b3;
  }
  