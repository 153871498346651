/* EmailVerification.css */
.email-verification-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .email-verification-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .email-verification-container #status {
    font-size: 18px;
    color: #333;
  }
  
  .email-verification-container .message {
    margin-top: 20px;
    font-size: 16px;
    color: #ff0000;
  }
  