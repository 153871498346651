/* AddProduct.css */
.add-product-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .add-product-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .add-product-container form {
    display: flex;
    flex-direction: column;
  }
  
  .add-product-container form div {
    margin-bottom: 15px;
  }
  
  .add-product-container form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
   
  .add-product-container form input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-product-container form input[type="checkbox"] {
    margin-left: 5px;
  }
  
  .add-product-container form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-product-container form button:hover {
    background-color: #0056b3;
  }
  