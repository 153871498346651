.order-details-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.filtersOrder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filtersOrder input,
.filtersOrder select,
.filtersOrder button {
    margin: 0 10px;
    padding: 10px;
}

/* CSS/OrderDetails.css */

/* General Styles */
.order-details-container {
    padding: 20px;
}

.filtersOrder {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.filtersOrder select, .filtersOrder input, .filtersOrder button {
    padding: 10px;
    font-size: 16px;
}.order-details-container {
    padding: 20px;
}

.filtersOrder {
    margin-bottom: 20px;
}

.filtersOrder select, .filtersOrder input, .filtersOrder button {
    margin-right: 10px;
    padding: 5px;
}

.order-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.order-details-table th, .order-details-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.expandable-row {
    cursor: pointer;
    position: relative;
}

.expand-collapse-button {
    background-color: #f2eded;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-right: 10px;
}

.expand-collapse-button:hover {
    background-color: #4bc89c;
}

.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-right: 5px;
    transition: transform 0.3s ease;
}

.arrow.expanded {
    border-top: 10px solid #c46767;
    transform: rotate(180deg);
}

.arrow.collapsed {
    border-bottom: 10px solid #830909;
}

.order-details {
    margin-top: 10px;
}

.order-details.hidden {
    display: none;
}

.order-details.visible {
    display: block;
}

.product-image-wrapper {
    display: flex;
    align-items: center;
}

.product-image {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.product-name {
    margin-left: 10px;
}
